
import rules from "@/mixins/rules"
import { countryMapping, genderMapping } from "@/helpers/mapping"

export default {
    name: "CheckoutForm",
    mixins: [rules],
    props: {
        /** Contact data object: { contactPersonName, businessName, contactPersonEmail, contactPersonPhone, postalCode, city } */
        address: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        businessName: null,
        contactPersonName: null,
        contactPersonGender: null,
        contactPersonEmail: null,
        contactPersonPhone: null,
        street: null,
        postalCode: null,
        city: null,
        countryCode: "DE",
        // values
        genders: genderMapping,
        countries: countryMapping,
    }),
    created() {
        this.resetForm()
    },
    methods: {
        resetForm() {
            this.businessName =
                this.address?.businessName || this.$auth.user?.business?.name
            this.contactPersonGender =
                this.address?.contactPersonGender ||
                this.$auth.user?.business?.account_contact_person?.gender
            this.contactPersonName =
                this.address?.contactPersonName ||
                this.$auth.user?.business?.account_contact_person?.fullname
            this.contactPersonEmail =
                this.address?.contactPersonEmail || this.$auth.user?.email
            this.contactPersonPhone =
                this.address?.contactPersonPhone ||
                this.$auth.user?.business?.account_contact_person?.phone_number
            this.street =
                this.address?.street ||
                this.$auth.user?.business?.primary_address?.street
            this.postalCode =
                this.address?.postalCode ||
                this.$auth.user?.business?.primary_address?.postal_code
            this.city =
                this.address?.city ||
                this.$auth.user?.business?.primary_address?.city
            this.countryCode =
                this.address?.country_code ||
                this.$auth.user?.business?.primary_address?.country_code ||
                "DE"
        },
        validate() {
            return this.$refs.form.validate()
        },
        getData() {
            return {
                businessName: this.businessName,
                contactPersonGender: this.contactPersonGender,
                contactPersonName: this.contactPersonName,
                contactPersonEmail: this.contactPersonEmail,
                contactPersonPhone: this.contactPersonPhone,
                street: this.street,
                postalCode: this.postalCode,
                city: this.city,
                countryCode: this.countryCode,
            }
        },
        onInputEnter(index) {
            // 7 - number of inputs
            if (index === 7) return
            this.$refs[`input-${index + 1}`].focus()
        },
    },
}


import CheckoutForm from "@/components/order/CheckoutForm"
import Button from "@/components/base/Button"
import { productToNumberOfJobs } from "@/helpers/mapping"
import { formatPriceWithoutDecimals } from "@/helpers/formatting"

export default {
    name: "EinBlickCheckoutForm",
    emit: ["checkout"],
    components: {
        CheckoutForm,
        Button,
    },
    props: {
        product: {
            type: Object,
            default: null,
        },
    },
    computed: {
        numberOfJobs() {
            return productToNumberOfJobs(this.product?.slug)
        },
        price() {
            return formatPriceWithoutDecimals(this.product?.price / 12, true)
        },
        free() {
            return this.product?.price === 0
        },
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        getData() {
            return this.$refs.form.getData()
        },
    },
}
